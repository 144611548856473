<template>
<svg class='svg-icon' aria-hidden='true'>
  <use :xlink:href='name' />
</svg>
</template>
<script lang='ts' setup>

const props = defineProps({
  iconName: {
      type: String,
      required: true
  },
  // className: {
  //     type: String,
  //     default: ''
  // }
})
const name = `#icon-${props.iconName}`
// const svgClass = props.className ? `svg-icon ${props.className}` : 'svg-icon '
</script>

<style lang='postcss' scoped>
.svg-icon {
    width: 2em;
    height: 2em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>