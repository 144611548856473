<template>
  <div class="my-dashboard">
    <div>
      <h2 class="text-lg pt-2 font-bold text-warm-gray-700">首页</h2>
      <el-divider></el-divider>
    </div>
    <p class="mt-4">欢迎您，这是您的工作台！</p>
    <p class="mt-4 mb-10">北京时间：{{now}}</p>

  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
var now = dayjs().utcOffset(8).format("YYYY年MM月DD HH:mm")
</script>