import { useMenuStore } from '../../store/menuStore';
// const Permission:Directive = {
//   mounted (el, binding) {
//     checkPermission(el,binding)
//   },
//   updated(el,binding) {
//     checkPermission(el,binding)
//   }
// }


//函数指令， mounted 和 updated 时触发相同行为，而不关心其他的钩子函数
/**
 * v-permission,接受数组参数，例如 v-permission = "['add','remove']"，全部满足才可以
 * @param el 
 * @param binding 
 */
const hasPermission = (element)=>{
  const mainMenu = useMenuStore();
  const permissions = mainMenu.permissions as Array<string>;
  //console.log("-------当前所有的权限 in permissions-----------",permissions,element)
  return permissions.includes(element);
}
const checkPermission = (el,binding)=>{
  var needPermission = binding.value 
  //console.log("权限指令mounted", Array.isArray(needPermission))
  if(binding.value && Array.isArray(needPermission)){
    if(needPermission.length > 0){
      if(!needPermission.every(hasPermission)){
        //没有权限
        el.parentNode && el.parentNode.removeChild(el)
      }
    }else {
      throw new Error(`v-permission指令接受一个数组表达式作为参数,且数组中至少有一个元素;例如：v-permission = "['add','admin']" `)
    }
  }else {
    throw new Error(`v-permission指令接受一个数组表达式作为参数,且数组中至少有一个元素;例如：v-permission = "['add','admin']" `)
  }
}


export default checkPermission

